const en = {
	editable: "Editable",
	search_field: "Search field",
	field_not_found: "Field not found",
	clear: "Clear",
	align: "Align",
	add_all_required_fields_to_continue: "Add all required fields to continue",
	uploaded_pdf_contains_form_fields_keep_or_remove_them:
		"Uploaded PDF contains form fields. Keep or remove them?",
	keep: "Keep",
	left: "Left",
	validation: "Validation",
	right: "Right",
	center: "Center",
	description: "Description",
	display_title: "Display title",
	with_logo: "With logo",
	unchecked: "Unchecked",
	type_value: "Type value",
	equal: "Equal",
	not_equal: "Not equal",
	contains: "Contains",
	does_not_contain: "Does not contain",
	not_empty: "Not empty",
	empty: "Empty",
	select_field_: "Select field...",
	select_value_: "Select value...",
	remove_condition: "Remove condition",
	add_condition: "Add condition",
	are_you_sure: "Are you sure?",
	sign_yourself: "Sign Yourself",
	set_signing_date: "Set signing date",
	signing_date: "Signing Date",
	send: "Send",
	remove: "Remove",
	edit: "Edit",
	settings: "Settings",
	up: "Up",
	down: "Down",
	checked: "Checked",
	save: "Save",
	cancel: "Cancel",
	any: "Any",
	drawn: "Drawn",
	formula: "Formula",
	typed: "Typed",
	draw_field_on_the_document: "Draw {field} field on the document",
	click_to_upload: "Click to upload",
	or_drag_and_drop_files: "or drag and drop files",
	uploading: "Uploading",
	processing_: "Processing...",
	add_pdf_documents_or_images: "Add PDF documents or images",
	add_documents_or_images: "Add documents or images",
	required: "Required",
	default_value: "Default value",
	format: "Format",
	read_only: "Read-only",
	page: "Page",
	draw_new_area: "Draw New Area",
	copy_to_all_pages: "Copy to All Pages",
	add_option: "Add option",
	option: "Option",
	condition: "Condition",
	first_party: "First Party",
	second_party: "Second Party",
	third_party: "Third Party",
	fourth_party: "Fourth Party",
	fifth_party: "Fifth Party",
	sixth_party: "Sixth Party",
	seventh_party: "Seventh Party",
	eighth_party: "Eighth Party",
	ninth_party: "Ninth Party",
	tenth_party: "Tenth Party",
	eleventh_party: "Eleventh Party",
	twelfth_party: "Twelfth Party",
	thirteenth_party: "Thirteenth Party",
	fourteenth_party: "Fourteenth Party",
	fifteenth_party: "Fifteenth Party",
	sixteenth_party: "Sixteenth Party",
	seventeenth_party: "Seventeenth Party",
	eighteenth_party: "Eighteenth Party",
	nineteenth_party: "Nineteenth Party",
	twentieth_party: "Twentieth Party",
	draw: "Draw",
	add: "Add",
	or_add_field_without_drawing: "Or add field without drawing",
	text: "Text",
	number: "Number",
	signature: "Signature",
	initials: "Initials",
	date: "Date",
	image: "Image",
	file: "File",
	select: "Select",
	checkbox: "Checkbox",
	multiple: "Multiple",
	radio: "Radio",
	cells: "Cells",
	stamp: "Stamp",
	payment: "Payment",
	phone: "Phone",
	field: "Field",
	group: "Group",
	draw_a_text_field_on_the_page_with_a_mouse:
		"Draw a text field on the page with a mouse",
	draw_field: "Draw {field} Field",
	replace: "Replace",
	uploading_: "Uploading...",
	add_document: "Add Document",
	none: "None",
	ssn: "SSN",
	ein: "EIN",
	email: "Email",
	url: "URL",
	zip: "ZIP",
	custom: "Custom",
	numbers_only: "Numbers only",
	letters_only: "Letters only",
	regexp_validation: "Regexp validation",
};

export { en };
